import React from 'react'

// custom components
import Header from './Header'
import '../styles/main.scss'

const Layout = ({ children, ...props }) => {
  return (
    <>
      <Header />
      <main>{children}</main>
    </>
  )
}

export default Layout
